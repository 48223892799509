<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :showDeleteBtn="false"></pui-datatable>
		<distressalerts-modals :modelName="modelName"></distressalerts-modals>
	</div>
</template>

<script>
import distressalertsActions from './DistressalertsActions';
import distressalertsModals from './DistressalertsModals.vue';

export default {
	name: 'distressalerts-grid',
	components: {
		'distressalerts-modals': distressalertsModals
	},
	data() {
		return {
			modelName: 'distressalerts',
			actions: distressalertsActions.gridactions,
			modelColumnDefs: {
				incidentreportstatus: {
					render: (data) => {
						if (data === 'Submitted') {
							return this.$t('grid.portcalls.statuslist.status.S');
						} else if (data === 'Pending') {
							return this.$t('grid.portcalls.statuslist.status.P');
						} else {
							return '';
						}
					}
				},
				missionreportstatus: {
					render: (data) => {
						if (data === 'Submitted') {
							return this.$t('grid.portcalls.statuslist.status.S');
						} else if (data === 'Pending') {
							return this.$t('grid.portcalls.statuslist.status.P');
						} else {
							return '';
						}
					}
				}
			}
		};
	}
};
</script>
